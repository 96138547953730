<template>
    <v-container class="pa-0"  fluid>
        

        <v-tabs v-model="tab" show-arrows   :vertical="!$vuetify.breakpoint.smAndDown">
            <v-tab>
                <v-icon left>
                    mdi-bullseye-arrow
                </v-icon>
                Objetivos
            </v-tab>
            <v-tab>
                <v-icon left>
                    mdi-bandage
                </v-icon>
                Lesiones
            </v-tab>
            <v-tab>
                <v-icon left>
                    mdi-dumbbell
                </v-icon>
                Equipo
            </v-tab>
            <v-tab disabled>
                <v-icon left>
                    mdi-play
                </v-icon>
                Rutinas
            </v-tab>

              <v-tab >
                <v-icon left>
                    mdi-chart-areaspline
                </v-icon>
                Métricas 
            </v-tab>
             <v-tab disabled>
                <v-icon left>
                    mdi-food-apple
                </v-icon>
                Nutrición
            </v-tab>



           <v-tab-item >

          


            <v-card>
                <v-card-title>
                    <v-icon left>
                        mdi-bullseye-arrow
                    </v-icon>
                    Objetivos de Entrenamiento

                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="ml-2" color="primary"> 
                                mdi-help-circle
                            </v-icon>
                        </template>
                        <span>
                                           Esta sección permite seleccionar los objetivos de entrenamiento de tus clientes.

                        </span>
                    </v-tooltip>




                </v-card-title>
                <v-card-text>
                    
                       <v-autocomplete v-model="user.objectives" :items="availableObjectives" label="Seleccionar" @change="checkMaxSelection" counter="5"
                         class="rounded-lg" filled rounded multiple :menu-props="{ maxHeight: 230, closeOnContentClick: false }"
                        :rules="[maxSelection(5, 'objectives')]" small-chips >
                    </v-autocomplete>
                   
                     
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                     <v-btn @click="updateUserData" elevation="0" :loading='loading' color="success">
                        <v-icon left>
                            mdi-content-save
                        </v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
            

         


           </v-tab-item>

           <v-tab-item>
            <v-card>
                <v-card-title>
                    <v-icon left>
                        mdi-bandage
                    </v-icon>
                    Limitaciones

                     <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="ml-2" color="primary"> 
                                mdi-help-circle
                            </v-icon>
                        </template>
                        <span>
                            Aqui puedes agregar las lesiones que ha tenido tu cliente.<br/> A modo de brindar un mejor servicio y evitar lesiones futuras.
                        </span>
                    </v-tooltip>
                </v-card-title>
                <v-card-text>

                    <v-alert type="info" text v-if="user.injuries.length ==0" >
                       No hay lesiones registradas 
                    </v-alert>



<v-form ref="form">
    <v-expansion-panels v-model="injuriesPanel">
      <v-expansion-panel
        v-for="(injury, index) in user.injuries"
        :key="index"
      >
        <v-expansion-panel-header>
            <span>
                  <v-icon
                    :color="injury.recuperado ? 'green lighten-3' : 'red lighten-3'"
                >
                    mdi-medical-bag
                </v-icon>
            </span>
              
          <span>{{ injury.nombre || 'Nueva Lesión' }}</span>
          
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-row >
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="injury.nombre"
                label="Nombre de lesión"
                :rules="[v => !!v || 'Este campo es requerido']"
                maxlength="50"
                filled
                rounded
                dense></v-text-field>
            </v-col>

             <v-col cols="6" sm="6">
              <v-menu
                ref="menu"
                v-model="injury.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense
                    v-model="injury.cuando"
                    label="Cuando"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    filled
                    dense
                    rounded
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="injury.cuando"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="injury.menu = false"
                    >Cancelar</v-btn
                  >
                  <v-btn text color="primary" @click="injury.menu = false"
                    >Confirmar</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>

             

           

            <v-col cols="6" sm="6">
              <v-text-field dense
                v-model="injury.deporte"
                label="Deporte"
                maxlength="50"
                prepend-inner-icon="mdi-soccer"
                filled
                rounded
                hide-details
              ></v-text-field>
            </v-col>

          

            <v-col cols="12" sm="6" v-if="isAuthorized">
              <v-textarea 
                v-model="injury.observation"
                label="Observaciones"
                maxlength="150"
                auto-grow
                filled
                rounded
                rows="1"
                hide-details
              ></v-textarea>
            </v-col>


             <v-col cols="6" sm="3">
              <v-checkbox
                v-model="injury.recuperado"
                label="Recuperado"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red"  @click="removeInjury(index)">
              <v-icon left>
                mdi-delete
              </v-icon>
              Borrar Lesión
            </v-btn>
          </v-card-actions>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>






                </v-card-text>
                <v-card-actions>
                     <v-btn @click="addInjury" elevation="0" class="mt-2">
                        <v-icon left>
                            mdi-plus
                        </v-icon>
                        Agregar lesión
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn @click="updateUserData" elevation="0" :loading='loading' color="success" class="mt-2 ml-3">
                        <v-icon left>
                            mdi-content-save
                        </v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
         
      

                  

                   
                  
           </v-tab-item>
            
            <v-tab-item>
                <v-card>
                    <v-card-title>
                        <v-icon left>
                            mdi-dumbbell
                        </v-icon>
                        Equipo



                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="ml-2" color="primary"> 
                                mdi-help-circle
                            </v-icon>
                        </template>
                        <span>
                            Aqui puedes seleccionar el equipo con el que cuenta tu cliente.
                        </span>
                    </v-tooltip>
                    </v-card-title>
                    <v-card-text>
                         <v-autocomplete v-model="user.equipment" :items="equipment" label="Seleccionar"
                          class="rounded-lg" filled rounded multiple
                         small-chips >
                      
                    </v-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="updateUserData" elevation="0" :loading='loading' color="success">
                            <v-icon left>
                                mdi-content-save
                            </v-icon>
                            Guardar
                        </v-btn>
                    </v-card-actions>
                       

                </v-card>
            </v-tab-item>


            <v-tab-item>
                <v-card>
                    <v-card-title>
                        <v-icon left>
                            mdi-play
                        </v-icon>
                        Rutinas

                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="ml-2" color="primary"> 
                                mdi-help-circle
                            </v-icon>
                        </template>
                        <span>
                            Aqui puedes seleccionar las rutinas de entrenamiento de tu cliente.
                        </span>
                    </v-tooltip>
                    </v-card-title>
                    <v-card-text>
                        <v-alert type="info" text v-if="!user.routines || user.routines.length ==0" >
                            No hay rutinas registradas 
                        </v-alert>

                        <v-list>
                            <v-list-item-group v-model="user.routines">
                                <v-list-item v-for="(routine, index) in user.routines" :key="index">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ routine }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon @click="removeRoutine(index)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="updateUserData" elevation="0" :loading='loading' color="success">
                            <v-icon left>
                                mdi-content-save
                            </v-icon>
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-tab-item>
            
            <v-tab-item>
                <v-card>
                    <v-card-title class="pb-0">
                        <v-icon left>
                            mdi-chart-areaspline
                        </v-icon>
                        Métricas
                    </v-card-title>

                    <Weights v-if="tab==4" :user="user"></Weights>
                </v-card>
            </v-tab-item>
       
        </v-tabs>


    </v-container>
</template>

<script>
import { getFirestore, doc, setDoc, updateDoc } from 'firebase/firestore'

import Weights from '@/components/Weights.vue';

import { logAuditEvent } from '@/error/audit.js';

export default {
    components: {
        Weights
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        isCoachView: {
            type: Boolean,
            default: false
        },
        isEvaluationView: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tab: 0,
            loading: false,
            menu: false,
            search: '',
            injuriesPanel: [],
            availableObjectives: [
                'Desarrollo de la fuerza',
                'Agilidad',
                'Resistencia',
                'Flexibilidad',
                'Velocidad',
                'Potencia',
                'Coordinación',
                'Hipertrofia',
                'Movilidad',
                'Estabilidad',
                'Equilibrio',
                'Postura',
                'Rehabilitación',
                'Prevención de lesiones',
                'Perder peso'
            ],
            availableObjectivesMarks: [
                'Objective Mark 1',
                'Objective Mark 2'
                // Add other marks as needed
            ],
            equipment: [
                { value: 'bodyweight', text: "Peso corporal" },
                { value: 'barbell', text: "Barra" },
                { value: 'dumbbell', text: "Mancuerna" },
                { value: 'kettlebell', text: "Pesa rusa" },
                { value: 'plate', text: "Disco" },
                { value: 'bench', text: "Banco" },
                { value: 'machine', text: "Máquina" },
                { value: 'cable', text: "Cable" },
                { value: 'box', text: "Caja" },
                { value: 'bands', text: "Bandas" },
                { value: 'ball', text: "Pelota" },
                { value: 'rope', text: "Cuerda" },
                { value: 'sled', text: "Trineo" },
                { value: 'other', text: "Otro" }
            ],
            
        }
    },
    methods: {
        checkMaxSelection() {
         

            if (this.user.objectives && this.user.objectives.length >5) {
                this.user.objectives.pop()
            }
        },
        addInjury() {
            this.user.injuries.push({
                nombre: null,
                cuando: null,
                como: null,
                deporte: null,
                recuperado: false
            })

            this.$forceUpdate()
        },
        removeInjury(index) {
            this.user.injuries.splice(index, 1)
        },
        async updateUserData() {

            try{
                this.loading = true;
                const db = getFirestore()
                const userRef = doc(db, 'users', this.$props.user.id)

                await updateDoc(userRef, {
                    objectives: this.user.objectives || [],
                    injuries: this.user.injuries || [],
                    equipment: this.user.equipment || []
                })

                this.$notify({
                    type: 'success',
                    title: 'Exito',
                    text: 'Datos actualizados correctamente.'
                })

                logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `User preferences updated ${JSON.stringify(this.user)}`)


                return true
            }
            catch(e){
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error updating user preferences ${e.message}`)
                this.$notify({
                    type: 'error',
                    title: 'Error',
                    text: 'Error al actualizar los datos.'
                })
                return false
            }
            finally{
                this.loading = false
            }
            
            

        },
        maxSelection(max, field) {
            return v => {
                if (v.length <= max) return true
                return `Maximo ${max} seleccionados.`
            }
        }
    },
    computed: {
        isAuthorized() {
            const userType = this.$store.state.Auth.token.claims.type;
            return ['superuser', 'admin', 'entrenador'].includes(userType);
        },
  },
}
</script>

<style scoped>
.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__selections {
    padding-top: 26px !important;
}

.v-tab{
    letter-spacing: unset !important;
}
</style>
